import React from 'react';

const services = [
    {
        title: 'Lipid Profile',
        items: [
            'Cholesterol - Total',
            'Cholesterol - HDL',
            'Cholesterol - LDL',
            'Cholesterol VLDL',
            'Triglycerides',
            'Total cholesterol/HDL',
            'LDL / HDL'
        ],
        count: 7 // Number of tests
    },
    {
        title: 'Liver Function Profile',
        items: [
            'Bilirubin Total',
            'Bilirubin Direct',
            'Bilirubin Indirect',
            'Alkaline Phosphatase (ALP)',
            'Alanine Transaminase (ALT/SGPT)',
            'Aspartate Aminotransferase(AST/SGOT))',
            'Y- Glutamyl Transferase (GGT)',
            'Protein Total',
            'Albumin',
            'Globulin',
            'Albumin/Globulin'
        ],
        count: 11 // Number of tests
    },
    {
        title: 'Kidney Profile',
        items: [
            'Creatinine',
            'Urea',
            'Blood Urea Nitrogen (BUN)',
            'Blood Urea Nitrogen (BUN)/Creatinine',
            'Sodium',
            'Potassium',
            'Chloride',
            'Uric Acid',
            'Glomerular Filtration Rate (eGFR)'
        ],
        count: 9 // Number of tests
    },
    {
        title: 'IRON PROFILE',
        items: [
            'Iron',
            'Iron Binding Capacity - Total (TIBC)',
            'Transferrin',
            'Transferrin %'
        ],
        count: 4 // Number of tests
    },
    {
        title: 'Thyroid Profile-II',
        items: [
            'Triiodothyronine Total (TT3)',
            'Triiodothyronine Free (FT3)',
            'Thyroxine - Total (TT4)',
            'Thyroxine - Free (FT4)',
            'Thyroid Stimulating Hormone (TSH)'
        ],
        count: 5 // Number of tests
    },
    {
        title: 'Albumin/Creatinine Ratio',
        items: [
            'Urine Microalbumin',
            'Urine Creatinine',
            'Albumin/creatinine'
        ],
        count: 3 // Number of tests
    },
    {
        title: 'Glycosylated Hemoglobin (GHb/HBA1c)',
        items: [
            'HbA1cEstimated Average Glucose (eAG)'
        ],
        count: 1 // Number of tests
    },
    {
        title: 'Complete Blood Count + ESR',
        items: [
            'Hemoglobin (Hb)',
            'Erythrocyte Count (RBC Count)',
            'PCV (Hematocrit)',
            'Platelet Count',
            'MCV',
            'MCH',
            'MCHC',
            'RDW - CV',
            'WBC Count',
            'Neutrophils',
            'Lymphocytes',
            'Eosinophils',
            'Monocytes',
            'Basophils',
            'Erythrocyte Sedimentation Rate (ESR)'
        ],
        count: 15 // Number of tests
    },
    {
        title: 'Calcium & Phosphorus',
        items: [
            'Calcium',
            'Phosphorus'
        ],
        count: 2 // Number of tests
    },
    {
        title: 'Complete Urine Analysis (CUE)',
        items: [
            'Color Appearance',
            'Specific gravity Reaction (pH)',
            'Proteins',
            'Glucose',
            'Bile salts & Bile pigments',
            'Ketones',
            'Blood',
            'Urobilinogen',
            'Nitrites',
            'PUS (WBC) Cells',
            'Urine RBC',
            'U.Epithelial Cells',
            'Casts & Crystals'
        ],
        count: 13 // Number of tests
    }
];

const Services: React.FC = () => {

    const handleBookClick = (serviceTitle: string) => {
        const phoneNumber = '8501805878';
        const message = `I am looking for ${serviceTitle} service, please help me!`;
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className="bg-white-50 py-10 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-center text-[#9254A9] mb-8">Full Body Check (70+ Tests)</h1>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {services.map((service, index) => (
                    <div
                        key={index}
                        className="p-6 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform duration-300"
                    >
                        <h2 className="text-2xl text-[#0EB268] font-semibold mb-4">{service.title} ({service.count})</h2>
                        <ul className="mb-6 space-y-2">
                            {service.items.map((item, idx) => (
                                <li key={idx} className="text-sm">{item}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className='py-12 text-center'>
                <a
                    href="https://wa.me/93909 90511?text=I am interested in booking a health check-up."
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-600 text-white py-2 px-6 rounded-full text-lg font-medium hover:bg-blue-700 transition-colors duration-300"
                >
                    Book Now
                </a></div>
        </div>
    );
};

export default Services;
