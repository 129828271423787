import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer: React.FC = () => {
    return (
        <footer className="bg-gray-800 text-white py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div>
                        <h2 className="text-2xl font-bold mb-4">Diagnostic Centre</h2>
                        <p>
                            Providing quality diagnostic services with the latest technology
                            and expert staff. Your health is our priority.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Services</h3>
                        <ul>
                            <li className="mb-2"><a href="#x-ray" className="hover:underline">X-Ray</a></li>
                            <li className="mb-2"><a href="#mri" className="hover:underline">MRI</a></li>
                            <li className="mb-2"><a href="#ultrasound" className="hover:underline">Ultrasound</a></li>
                            <li className="mb-2"><a href="#blood-tests" className="hover:underline">Blood Tests</a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
                        <ul>
                            <li className="mb-2"><a href="#home" className="hover:underline">Home</a></li>
                            <li className="mb-2"><a href="#about" className="hover:underline">About Us</a></li>
                            <li className="mb-2"><a href="#services" className="hover:underline">Services</a></li>
                            <li className="mb-2"><a href="#contact" className="hover:underline">Contact</a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
                        {/* <p>123 Health St, Wellness City</p>
                        <p>Email: contact@diagnosticcentre.com</p> */}
                        <p>Phone: 8501805878</p>
                        <div className="flex space-x-4 mt-4">
                            {/* <a href="#facebook" className="text-white hover:text-gray-400"><FaFacebook size={24} /></a>
                            <a href="#twitter" className="text-white hover:text-gray-400"><FaTwitter size={24} /></a> */}
                            <a href="#instagram" className="text-white hover:text-gray-400"><FaInstagram size={24} /></a>
                            {/* <a href="#linkedin" className="text-white hover:text-gray-400"><FaLinkedin size={24} /></a> */}
                        </div>
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-700 pt-4 text-center">
                    <p>&copy; 2024 Diagnostic Centre. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
