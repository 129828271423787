import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Services from './components/Services/Services';
import HeroSection from './components/HeroSection/HeroSection';

const App: React.FC = () => {
  return (
    <div>
      <Navbar />
      <main>
        {/* Your other components go here */}
        <HeroSection />
        <Services />
      </main>
      <Footer />
    </div>
  );
};

export default App;
