import React from 'react';

const HeroSection = () => {
    return (
        <div className="bg-FEFAF9 py-10 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto flex flex-col items-center text-center">
                <h1 className="text-4xl mt-24 font-bold  text-[#9254A9] mb-4">
                    Health Test at your Home
                </h1>
                <p className="text-lg text-gray-700 mb-8">
                    Ensure your well-being with our full body check-ups and specialized health screenings. Book your appointment now and take the first step towards a healthier you.
                </p>
                <a
                    href="https://wa.me/93909 90511?text=I am interested in booking a health check-up."
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-600 text-white py-2 px-6 rounded-full text-lg font-medium hover:bg-blue-700 transition-colors duration-300"
                >
                    Book Now
                </a>
            </div>
        </div>
    );
};

export default HeroSection;
