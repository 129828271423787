import React from 'react';

const Navbar: React.FC = () => {
    return (
        <nav className="bg-white-50 shadow-md fixed w-full z-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex items-center">
                        <img
                            src="./healthbarosa_logo.jpeg"
                            alt="Brand Logo"
                            className="h-44 w-auto"
                        />
                        {/* <span className="text-2xl font-bold text-[#9254A9] ml-3">Your Brand</span> */}
                    </div>
                    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                        <a
                            href="#home"
                            className="text-gray-800 inline-flex items-center px-1 pt-1 border-b-2 border-transparent hover:border-[#9254A9] text-lg font-medium"
                        >
                            Home
                        </a>
                        <a
                            href="#services"
                            className="text-gray-800 inline-flex items-center px-1 pt-1 border-b-2 border-transparent hover:border-[#9254A9] text-lg font-medium"
                        >
                            Services
                        </a>
                        <a
                            href="#contact"
                            className="text-gray-800 inline-flex items-center px-1 pt-1 border-b-2 border-transparent hover:border-[#9254A9] text-lg font-medium"
                        >
                            Contact
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
